.App {
  color: white;
}

.App-logo {
  animation: StargasmLogoAnimation infinite 0.65217391304s linear;
  height: 40vmin;
  pointer-events: none;
  opacity: 0.725;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: gray;
}

.vid {
  z-index: -2;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
}

.App-link {
  color: #61dafb;
}

.AppText {
  animation: HeroFadeIn 12s linear;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.callToAction {

}

img {

}

.youtubePlayerContainer {
  height: 100%;
  width: 100%;
}

span {
  text-align: center;
}


@keyframes HeroFadeIn {
  30% {
    opacity: 1;
  }

  35%{
    opacity: 0;
  }

  80%{
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes StargasmLogoAnimation {
  0% {
    transform: scale(1,1);
  }
  10% {
    transform: scale(1.025,1.025);
  }
  90% {
      transform: scale(1, 1);
    }
}
