body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  color: white;
}

.App-logo {
  -webkit-animation: StargasmLogoAnimation infinite 0.65217391304s linear;
          animation: StargasmLogoAnimation infinite 0.65217391304s linear;
  height: 40vmin;
  pointer-events: none;
  opacity: 0.725;
}

.App-header {
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: gray;
}

.vid {
  z-index: -2;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
}

.App-link {
  color: #61dafb;
}

.AppText {
  -webkit-animation: HeroFadeIn 12s linear;
          animation: HeroFadeIn 12s linear;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.callToAction {

}

img {

}

.youtubePlayerContainer {
  height: 100%;
  width: 100%;
}

span {
  text-align: center;
}


@-webkit-keyframes HeroFadeIn {
  30% {
    opacity: 1;
  }

  35%{
    opacity: 0;
  }

  80%{
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}


@keyframes HeroFadeIn {
  30% {
    opacity: 1;
  }

  35%{
    opacity: 0;
  }

  80%{
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes StargasmLogoAnimation {
  0% {
    -webkit-transform: scale(1,1);
            transform: scale(1,1);
  }
  10% {
    -webkit-transform: scale(1.025,1.025);
            transform: scale(1.025,1.025);
  }
  90% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
}

@keyframes StargasmLogoAnimation {
  0% {
    -webkit-transform: scale(1,1);
            transform: scale(1,1);
  }
  10% {
    -webkit-transform: scale(1.025,1.025);
            transform: scale(1.025,1.025);
  }
  90% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
}

.componentStyle{
    display: block;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    height: 3.5em;
    padding-top: 1em;
}

.headerBar {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    -webkit-align-items: center;
            align-items: center;
    height: 2em;
    margin-right: 1em;
    margin-left: 1em;
}

.headerItem {
    margin: 0.5em;
    height: 100%;
    padding: 1em 0;
}

#volumeHeader {
    padding: 0;
    margin: 0;
    color: white;
}

#unMuteButtonIcon {
    height: 100%;
}

#playButtonIcon {
    height: 100%;
}

.unMuteButtonContainer {
    height: 100%;
    font-family: none;
}

h5 {
    margin: 0;
    padding: 0;
}
